import React, { Component } from 'react'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import ScrollManager from '../helper/scrollManager'
import { easeFunction, animations } from '../helper/variables'
import posed from 'react-pose'
import { connect } from 'react-redux'
import SplitText from 'react-pose-text'
import VisibilityWrapper from '../helper/visibilityWrapper'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'
import _ from 'lodash'
import Parser from 'html-react-parser'
import Footer from '../components/common/footer'

import './insight.css'
import {
  IconFacebook,
  IconTwitter,
  IconLinkedIn,
} from '../components/svg/snsIcons'
import Parallax from '../helper/parallax'
import Thumbnail from '../components/insight/thumbnail'
import { isBrowser, BrowserView } from 'react-device-detect'
import { graphql } from 'gatsby'
import SectionIndicator from '../components/common/sectionIndicator'
import ReactGA from 'react-ga'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Insight extends Component {
  slideAnimation = animations('SLIDE')
  ariseAnimation = animations('ARISE')

  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    windowHeight: 0,
    triggerPointBody: 0,
    triggerPointFooter: 0,
    randomPosts: null,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionContent = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
    ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.getTriggerPoints()
    this.pickRandom()

    this.setState({
      mainNode: this.mainNode.current,
    })

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.getTriggerPoints)

    this.props.hideSymbol()
    this.props.backgroundBlack()
    this.props.noHomepageLoading()
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.getTriggerPoints)
  }

  getTriggerPoints = () => {
    const windowHeight = typeof window !== 'undefined' && window.innerHeight
    const { momentumScrollValue } = this.state

    this.setState({
      windowHeight: windowHeight,
      triggerPointBody:
        momentumScrollValue +
        this.sectionContent.current.getBoundingClientRect().top,
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })
  }

  pickRandom = () => {
    const selfRemoved = _.differenceWith(
      this.props.data.allContentfulInsight.edges,
      [{ node: this.props.pageContext.data }],
      function(article1, article2) {
        return article1.node.id === article2.node.id
      }
    )

    this.setState(
      {
        randomPosts: _.take(_.shuffle(selfRemoved), 2),
      },
      () => {
        setTimeout(this.getTriggerPoints, 500)
      }
    )
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value >= this.state.triggerPointBody - (isBrowser ? 75 : 55)) {
      this.props.backgroundWhite()
    } else {
      this.props.backgroundBlack()
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  getBackgroundSize = momentumScrollValue => {
    if (isBrowser) {
      const ratio = _.clamp(momentumScrollValue / this.state.windowHeight, 0, 1)

      return 1.1 - ratio * 0.1
    }
  }

  render() {
    const page = this.props.pageContext.data
    const { theme } = this.props
    const { momentumScrollValue, randomPosts } = this.state
    const url = this.props.location.href
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) =>{
          const isValidUrl = urlString=> {
            var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
          return !!urlPattern.test(urlString);
          }
          const description = node.data.target.fields.description["en-US"];
          const imageUrl = node.data.target.fields.file["en-US"].url;
          if (isValidUrl(description)) {
            return (
            <a href={description} target="_blank">
              <img 
                style={{maxWidth: '100%', display: "block"}}
                src={imageUrl}
              />
          </a>)
          }
          return <img 
            style={{maxWidth: '100%', display: "block"}}
            src={imageUrl}
          />
        }
        
      },
    }
    const richText = documentToReactComponents(page.bodyText.json, options)
    
    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta
            property="og:url"
            content={`https://www.baunfire.com/blog/${page.slug}`}
          />
          <link
            rel="canonical"
            href={`https://www.baunfire.com/blog/${page.slug}`}
          />
        </Helmet>
        <PageTransition>
          <div
            className="insight-top-background"
            style={{
              backgroundImage: `url(${page.keyImage.file.url})`,
              transform: `scale(${this.getBackgroundSize(
                momentumScrollValue
              )})`,
            }}
          />
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <div className="page-insight-detail">
              <section className="content-container head-container top-of-page">
                <div className="page-header">
                  <div className="header-left">
                    <h2 className="type-h11 color-red ribbon-text">
                      <SplitText
                        initialPose={isBrowser ? 'exit' : 'enter'}
                        pose="enter"
                        charPoses={this.slideAnimation}
                      >
                        {page.category}
                      </SplitText>
                    </h2>
                    <VisibilityWrapper>
                      {({ isVisible }) => {
                        return (
                          <h1 className={`type-h3 color-white headline`}>
                            <SplitText
                              initialPose={isBrowser ? 'exit' : 'enter'}
                              pose={isVisible ? 'enter' : 'exit'}
                              charPoses={this.ariseAnimation}
                            >
                              {page.title}
                            </SplitText>
                          </h1>
                        )
                      }}
                    </VisibilityWrapper>
                  </div>
                </div>
                <div className="social-share">
                  <FacebookShareButton
                    url={url}
                    quote={page.title}
                    className="sns-button"
                  >
                    <IconFacebook theme="white" />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={url}
                    title={page.title}
                    className="sns-button"
                  >
                    <IconTwitter theme="white" />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={url}
                    title={page.title}
                    className="sns-button"
                  >
                    <IconLinkedIn theme="white" />
                  </LinkedinShareButton>
                </div>
              </section>
              <section className="headline-container" ref={this.sectionContent}>
                <div className="content-container">
                  <h2 className="type-h1 headline-title">{page.headline}</h2>
                  <div className="headline-description">
                    {page.headDescription.headDescription}
                  </div>
                </div>
              </section>
              <section className="body-container">
                <div className="content-container">
                  {
                    
                  }
                  {richText}
                </div>
              </section>
              <section className="other">
                <div className="content-container">
                  <h3 className="type-h1">More Insights</h3>
                  <div className="insights-list">
                    {randomPosts &&
                      randomPosts.map((insight, index) => {
                        if (index % 2 === 1) {
                          return (
                            <Parallax
                              viewportScroll={momentumScrollValue}
                              moveRange="200"
                              key={insight.node.id}
                              disabledOnMobile
                            >
                              <Thumbnail data={insight.node} className="even" />
                            </Parallax>
                          )
                        } else {
                          return (
                            <Thumbnail
                              key={insight.node.id}
                              data={insight.node}
                            />
                          )
                        }
                      })}
                  </div>
                </div>
              </section>
            </div>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <SectionIndicator theme={theme}>Insight</SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allContentfulInsight(
      limit: 1000
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          category
          keyImage {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedInsight = connect(
  mapStateToProps,
  mapDispatchToProps
)(Insight)

export default ConnectedInsight
